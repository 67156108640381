<template>
  <v-data-table
    v-if="headers"
    :loading="loading"
    :headers="headers.filter(x => !x.expandData)"
    :items="data"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    item-key="ID"
    show-expand
    class="elevation-0"
    dark
    hide-default-footer
    :multi-sort="false"
    :sortBy="sortby"
    :sortDesc="sortdesc"
    @update:sort-by="handleSortBy"
    @update:sort-desc="handleSortDesc"
    mobile-breakpoint="800"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Registro de entrada</v-toolbar-title>
        <v-spacer />
        <export-pdf
          v-if="perms && perms.indexOf('a5c7ba9b-a5c1-4338-9235-c8a6c842882b') >= 0"
          :locale="locale"
          :workspaceID="workspaceID"
        />
        <!--v-btn
          v-if="perms && perms.indexOf('a5c7ba9b-a5c1-4338-9235-c8a6c842882b') >= 0"
          color="primary"
          @click="handleExportPDF"
          style="margin-right: 20px"
        >
          <v-icon small>mdi-file-pdf-box</v-icon>
        </v-btn-->
        <search v-if="onSearch" :onSearch="onSearch" :showClear="true" />
        <v-btn
          color="primary"
          @click="handleGetData"
        >
          refrescar
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.ident="{ item }">
      <a @click="handleCheckin(item)" style="color:#CCCCCC;text-decoration: underline;">{{item.ident.name}}</a>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-layout 
        wrap
        :style="{ 'min-width': perms && perms.indexOf('512c8864-52ca-445d-8c31-2a2e45132942') >= 0 ? '50px' : null}"
      >
        <v-flex xs6 
          v-if="perms && perms.indexOf('512c8864-52ca-445d-8c31-2a2e45132942') >= 0"
        >
        <delete-item
          :id="item.ID"
          :onDeleteItem="handleDeleteAttendance"
          :locale="locale"
          icon="true"
        />
      <!--v-btn small
      >
      <delete-item
        :id="item.ID"
        :onDeleteItem="handleDeleteAttendance"
        :locale="locale"
        icon="true"
      />
      </v-btn-->
        </v-flex>
        <v-flex xs6>
        <v-icon 
          small
          @click="handleCheckin(item)"
        >
          mdi-file-sign
        </v-icon>
      <!--v-btn
        color="primary"
        @click="handleCheckin(item)"
        style="margin-left: 10px;"
        small
      >
        <v-icon small>mdi-file-sign</v-icon>
      </v-btn-->
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <expanded-item 
          :item="item"
          :locale="locale"
          :perms="perms"
        />
      </td>
    </template>
    <template v-slot:footer>
      <pagination
        :startIndex="startIndex"
        :itemsPerPage="itemsPerPage"
        :count="count"
        :onGetData="handleGetData"
        :locale="locale"
        :dark="true"
      />
    </template>
  </v-data-table>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import Pagination from '@/components/customTable/Pagination'
import Search from '@/components/Search'
import DeleteItem from '@/components/DeleteItem'
import ExpandedItem from './ExpandedItem'
import ExportPdf from './ExportPdf'
export default {
  components: {
    ExpandedItem,
    Pagination,
    Search,
    DeleteItem,
    ExportPdf,
  },
  props: {
    locale: {
      type: String,
      required: true,
    },
    workspaceID: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    img: null,
    expanded: [],
    singleExpand: true,
    search: '',
    startIndex: 0,
    itemsPerPage: 5,
    count: 0,
    headers: null,
    data: [],
    perms: null,
    sortby: null,
    sortdesc: false,
    loading: false,
    /*data: [
      {
        id: '1',
        paxName: 'Frozen Yogurt',
        age: 4,
        room: 'K-309',
        locale: 'EN',
        guardianWhere: 'GYM',
        phone: '00 49 658594620',
        arriveOnly: true,
        goAlone: false,
        note: '',
      },
      {
        id: '2',
        paxName: 'Frozen Yogurt',
        age: 4,
        room: 'K-309',
        locale: 'EN',
        guardianWhere: 'GYM',
        phone: '00 49 658594620',
        arriveOnly: false,
        goAlone: true,
        note: '',
      },
    ],
    showSignatureForm: false,
    */
  }),
  mounted () {
    this.handleGetPermissions()
    this.prepareHeaders()
  },
  methods: {
    onSearch (v) {
      this.search = v
      this.handleGetData(0)
    },
    handleCheckin (v) {
      this.expanded = this.expanded.indexOf(v) >= 0 ? [] : [v]
    },
    handleGetPermissions () {
      this.items = []

      const url = `v1/internal/workspaces/${this.workspaceID}/sidebar`
      
      api.getAllWithoutLimit ('booking', url)
        .then(response => {
          this.perms = response.filter(x => !x.path).map(item => item.id).join(',')
        })
    },
    prepareHeaders () {
      api.getAllWithoutLimit ('booking', `v1/private/workspaces/${this.workspaceID}/attendance/form`)
        .then(response => {
          if (!response || response.length === 0) return
          const aux = response.map(item => {
            return {
              text: utils.getTranslation(item.Name, this.locale),
              value: item.ID,
              type: item.Type,
              isValues: true,
              expandData: item.ExpandData
            }
          })
          aux.unshift({
            text: utils.getTranslation({ es: 'Nombre tutor/responsable', en: 'Guardian name' }, this.locale),
            value: 'guardianName',
          })
          aux.unshift({
            text: utils.getTranslation({ es: 'Nombre asistente', en: 'Pax name' }, this.locale),
            value: 'ident',
          })
          aux.push({
            value: 'actions',
            sortable: false,
          })
          this.headers = aux
          this.handleGetData()
        })

      /*
      this.headers = [
        {
          text: 'Asistente',
          align: 'start',
          sortable: true,
          value: 'paxName',
        },
        { text: 'Edad', value: 'age' },
        { text: 'Habitaci�n', value: 'room' },
        { text: 'Idioma', value: 'locale' },
        { text: 'Tutor legal donde?', value: 'guardianWhere' },
        { text: 'Tel�fono', value: 'phone' },
        { text: '�vino solo?', value: 'arriveOnly' },
        { text: '�se puede ir solo?', value: 'goAlone' },
        { text: 'Observaciones', value: 'note' },
        { value: 'actions', align: 'end', sortable: false }
      ]
  */
    },
    convertValue (type, v) {
      if (type === 'boolean') {
        if (v === true) 
          return utils.getTranslation({ es: 'SI', en: 'YES'}, this.locale)
        else if (v === false) 
          return utils.getTranslation({ es: 'NO', en: 'NO'}, this.locale)
        else return ''
      }
      return v
    },
    prepareData (v) {
      const result = {
        ID: v.ID,
        ident: {
          id: v.ID,
          name: v.PaxName
        },
        guardianName: v.GuardianName,
      }
      for (const item of this.headers.filter(x => x.isValues)) {
        const aux = v.Values ? JSON.parse(v.Values) : {}
        // console.log(item.value, aux, aux[item.value])
        result[item.value] = this.convertValue(item.type, aux[item.value])
      }
      return result
    },
    prepareExpandData (v) {
      const result = []
      if (v) {
        const values = JSON.parse(v)
        for (const aux of this.headers.filter(x => x.expandData)) {
          result.push({ key: aux.text, value: values[aux.value]})
        }
      }
      return result
    },
    handleGetData (startIndex) {
      this.loading = true
      if (startIndex >=0 ) this.startIndex = startIndex
      //console.log(this.sortby, this.sortdesc)
      api.getAll ('booking', `v1/private/workspaces/${this.workspaceID}/attendance`, this.startIndex, this.itemsPerPage, this.search)
        .then(response => {
          if (!response) return
          this.count = response.count
          this.data = response.data.map(item => {
            const aux = this.prepareData(item)
            aux.ExpandDataValues = this.prepareExpandData (item.Values)
            return aux
          })
          this.loading = false
        })
    },
    handleSaveSignature (v) {
      this.img = v
    },
    handleDeleteAttendance (v) {
      api.deleteItem ('booking', `v1/private/attendance/`, v)
        .then(() => {
          this.handleGetData()
        })
    },
    handleSortBy (v) {
      let result = null
      const id = v
      const aux = this.headers.filter(x => x.value === id).shift()

      if (id === 'guardianName') result = 'GuardianName'
      else if (id === 'ident') result = 'PaxName'
      else result = aux ? aux.value : null

      if (!result) return

      this.sortby = result
      //this.handleGetData()
    },
    handleSortDesc (v) {
      this.sortdesc = v ? true : false

      //this.handleGetData()
    },
  },
}
</script>

