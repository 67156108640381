<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="330"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small>mdi-file-pdf-box</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title style="line-height: 1 !important;">
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
            style="padding: 10px 0;"
          />
          <v-date-picker v-if="!loading" v-model="date" color="primary" :locale="locale" />
        </v-card-title>
        <v-card-actions>
          <v-btn
            :disabled="loading"
            @click="dialog = false"
          >
          {{ $t('common.close', locale) }}
          </v-btn>
          <v-btn
            @click="handleExportPDF"
            color="primary"
            :disabled="loading"
          >
            PDF
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from '@/services/api'
export default {
  props: {
    locale: {
      type: String,
      required: true,
    },
    workspaceID: {
      type:  String,
      required: true,
    },
  },
  data () {
    return {
      dialog: false,
      date: new Date().toISOString().substr(0, 10),
      loading: false,
    }
  },
  methods: {
    handleExportPDF () {
      this.loading = true
      api.getAllWithoutLimit ('booking', `v1/private/attendance/pdf/${this.workspaceID}/${this.date}`, false, `&states=${this.showAll ? 'all' : ''}`)
        .then(response => {
          window.open(response, '_blank')
          this.loading = false
          this.dialog = false
        })
    },
  },
}
</script>
